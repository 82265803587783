body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: #31b0d5;
	text-decoration: none; 
}

a:hover {
    text-decoration: none;
}

h2 {
	font-size: 35px;
}

p {
  color: #333;
  font-size: 18px;
}

/* Headline */
.welcome-headline {
  text-align: center;
  background-color: #eee;
  height: 1px;
  margin: 20px 0 70px;
}

.welcome-headline h2 {
  background-color: #FFFFFF;
  display: inline-block;
  margin: -20px auto 0;
  padding: 0 20px;
  font-weight: 700;
}


/*****************/
/**** BUTTONS ****/
/****************/
.btn-cust {
	border-radius: 0px;
	margin-top: 20px;
}

/* Custom homepage button */
.btn-custom {
	border: none;
	font-family: inherit;
	font-size: 18px;
	color: inherit;
	background: none;
	cursor: pointer;
	padding: 25px 80px;
	display: inline-block;
	margin: 25px 0px;
	letter-spacing: 1px;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-custom:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.icon-arrow-right:before {
	content: "\2192";
}

.icon-arrow-left:before {
	content: "\2190";
}


/* Button 3 */
.btn-3 {
	background: #31b0d5;
	border-radius: 10px;
	padding: 20px 100px 20px 60px;
	overflow: hidden;
}

@media screen and (min-width: 768px) {
	.btn-3 {
		padding: 20px 80px 20px 30px;
	}
}

.btn-3:hover {
	background: #fcad26;
}

.btn-3:active {
	background: rgb(39, 39, 39);
	top: 2px;
}

.btn-3:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	margin-top: 15px;
	font-size: 140%;
	width: 35px;
	left: auto;
	right: 10px;
	z-index: 2;
}

.btn-3:after {
	width: 45%;
	height: 200%;
	background: rgba(255,255,255,0.1);
	z-index: 1;
	right: 0;
	top: 0;
	margin: -5px 0 0 -5px;
	-webkit-transform-origin: 0 0;
	-webkit-transform: rotate(-20deg);
	-moz-transform-origin: 0 0;
	-moz-transform: rotate(-20deg);
	-ms-transform-origin: 0 0;
	-ms-transform: rotate(-20deg);
	transform-origin: 0 0;
	transform: rotate(-40deg);
}

.btn-3:hover:after {
	width: 50%;
}

.icon-arrow-right-contact:before {
	content: "\2709";
}

/* custom button 1 */
.custom-btn-1 {
    display: inline-block;
    position: relative;
    height: 65px;
    background: transparent;
    overflow: hidden;
    width: 150px;
		margin-top: 20px;
}

.cust-btn-1 {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #31b0d5;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 65px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    transition: color 400ms ease-in;
}

.cust-btn-1:before {
    content: attr(data-title) " ";
    display: inline-block;
    position: absolute;
    top: 65px;
    left: 0;
    background-color: #31b0d5;
    color: #ffffff;
    height: 100%;
    font-weight: bold;
    text-align: center;
    width: 100%;
    transition: top 320ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.cust-btn-1:hover {
    color: #31b0d5;
}

.cust-btn-1:hover:before {
    top: 0;
}

/* Control styles */
.control {
	color: var(--base);
}

.ReactModalPortal img {
	background: white !important;
}
